<template>
  <v-row dense>
    <v-col cols="12">
      <APIDesigner
        :definition.sync="reportDefinition.query"
        type="data"
        :internal="true"
        @update:definition="updateDefinition"
      /> </v-col
  ></v-row>
</template>
<script>
import { STORE_CONSTS } from "@/views/applications/report/store";
const debug = require("debug")("atman.components.report_query"); // eslint-disable-line
export default {
  name: "ReportQuery",
  components: {
    APIDesigner: () => import("@/components/fields/Action/APIDesigner.vue"),
  },
  computed: {
    reportDefinition: {
      get() {
        const result =
          this.$store.state[STORE_CONSTS.REPORT_EDITOR_STORE][
            STORE_CONSTS.DEFINITION
          ];
        debug(`reportDefinition`, result);
        return result;
      },
      set(value) {
        this.$store.commit(
          `${STORE_CONSTS.REPORT_EDITOR_STORE}/${STORE_CONSTS.DEFINITION}`,
          value
        );
      },
    },
  },
  created() {
    debug(`In created of ReportQuery`);
  },
  mounted() {
    debug(`In mounted of ReportQuery`);
  },
  methods: {
    updateDefinition(input) {
      debug(`In updateDefinition`, input);
    },
  },
};
</script>
